import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {OverlayService} from '@aztrix/components/overlay';
import {EnvironmentService} from '@aztrix/environment';
import {JiraRequestType} from '@aztrix/models';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, map, shareReplay, switchMap, tap} from 'rxjs/operators';

import {FeedbackFormComponent} from '../feedback-form/feedback-form.component';
import {FeedbackService} from '../feedback.service';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {IconsModule} from '@aztrix/icons';
import {MatInputModule} from '@angular/material/input';
import {TranslatePipe} from '@aztrix/translate';
import {SpinnerModule} from '@aztrix/components/spinner';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconForJiraRequestTypePipe} from './icon-for-jira-request-type.pipe';

interface FeedbackItem {
  id: string;
  contentUrl: string;
  title: string;
}

@Component({
  selector: 'ax-feedback-search',
  templateUrl: './feedback-search.component.html',
  styleUrls: ['./feedback-search.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslatePipe,
    SpinnerModule,
    IconForJiraRequestTypePipe,
  ],
})
export class FeedbackSearchComponent implements OnInit {
  @Input() initValue = '';
  @Input() emailOptions = [];

  loadingResults$ = new BehaviorSubject<boolean>(true);
  loadingRequestTypes$ = new BehaviorSubject<boolean>(true);

  searchForm = new UntypedFormControl('');
  keyup$ = new BehaviorSubject<string>('');
  searchResults$ = this.keyup$.pipe(
    debounceTime(500),
    tap(() => this.loadingResults$.next(true)),
    switchMap((search) =>
      this._feedback.searchKnowledgeBase(search || 'how to').pipe(
        map((results) =>
          results.articles.map((article: FeedbackItem) => {
            // eslint-disable-next-line max-len
            article.contentUrl = `https://aztrix.atlassian.net/servicedesk/customer/kb/view/${article.id}`;
            return article;
          })
        )
      )
    ),
    tap(() => this.loadingResults$.next(false)),
    shareReplay(1)
  );

  requestTypes$ = this._feedback.searchKnowledgeBase('').pipe(
    map((results) => results.requestTypes),
    tap(() => this.loadingRequestTypes$.next(false))
  );

  portalUrl$ = this._environment
    .string$('JIRA_SERVICE_PORTAL_ID')
    .pipe(map((id) => `https://aztrix.atlassian.net/servicedesk/customer/portal/${id}`));

  constructor(
    private _elementRef: ElementRef,
    private _feedback: FeedbackService,
    private _overlayService: OverlayService,
    private _environment: EnvironmentService
  ) {}

  ngOnInit() {
    if (this.initValue !== '') {
      this.searchForm.setValue(this.initValue);
      this.keyup$.next(this.searchForm.value);
    }
  }

  openFeedbackModal(requestType: JiraRequestType): void {
    this._overlayService.closeModal();
    this._overlayService.createModal(this._elementRef, FeedbackFormComponent, {
      title: requestType.name,
      init: (modal) => {
        modal.requestType = requestType;
        modal.emailOptions = this.emailOptions;
      },
    });
  }
}
