import {CommonModule} from '@angular/common';
import {Component, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ActionFinishedComponent} from '@aztrix/components/action-finished';
import {ButtonModule} from '@aztrix/components/button';
import {OverlayService} from '@aztrix/components/overlay';
import {PropertySelectModule} from '@aztrix/components/property-select';
import {SpinnerModule} from '@aztrix/components/spinner';
import {ValueEditModule} from '@aztrix/components/value-edit';
import {CustomValidators, HelperPipesModule, PropertyFormControl} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {JiraRequestType, Profile, PropertyType} from '@aztrix/models';
import {TranslatePipe, TranslateService} from '@aztrix/translate';
import {FormGroup} from '@ngneat/reactive-forms';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {FeedbackService} from '../feedback.service';

@Component({
  selector: 'ax-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslatePipe,
    SpinnerModule,
    HelperPipesModule,
    ValueEditModule,
    PropertySelectModule,
    ButtonModule,
  ],
})
export class FeedbackFormComponent implements OnChanges {
  @Input() emailOptions = [];
  @Input() requestType: JiraRequestType;
  @Input() reportedProfile: Profile;

  feedbackForm = new FormGroup<any>({});

  private _attachment: File;

  constructor(
    private _elementRef: ElementRef,
    private _feedback: FeedbackService,
    private _translate: TranslateService,
    private _overlayService: OverlayService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.requestType && this.requestType) {
      this._createFeedbackForm(this.requestType);
    }
  }

  selectImage(event: any): void {
    this._attachment = event.target.files[0];
    this.feedbackForm.get('attachment')?.setValue(this._attachment.name);
  }

  submitFeedback(): void {
    if (this.feedbackForm.valid) {
      this.feedbackForm.disable();
      const {summary, email, description} = this.feedbackForm.value;

      let title = summary;
      if (this.reportedProfile) {
        title = `${summary} // profileId: ${this.reportedProfile.id}`;
      }

      this._feedback
        .submit(this.requestType.id, title, email.value, description, this._attachment)
        .pipe(
          catchError((error) => {
            this.feedbackForm.enable();
            return throwError(() => error);
          })
        )
        .subscribe(() => {
          this._overlayService.closeModal();
          this._overlayService.createModal(this._elementRef, ActionFinishedComponent, {
            title: 'modal.report.submit.success',
            init: (modal) => {
              modal.bodyExplanation = this._translate.instant('modal.report.submit.description');
              modal.didClickButton.subscribe(() => this._overlayService.closeModal());
            },
          });
        });
    }
  }

  get loading() {
    return !Object.values(this.feedbackForm.controls)?.length;
  }

  private _createFeedbackForm(requestType: JiraRequestType) {
    for (const field of requestType.fields) {
      switch (field.id) {
        case 'summary':
          this.feedbackForm.addControl(
            'summary',
            new FormControl('', [Validators.required, Validators.minLength(4)])
          );
          break;
        case 'description':
          this.feedbackForm.addControl(
            'description',
            new FormControl('', [
              Validators.required,
              Validators.minLength(10),
              Validators.maxLength(2000),
            ])
          );
          break;
        case 'email':
          this.feedbackForm.addControl(
            'email',
            PropertyFormControl.createByType(PropertyType.EMAIL, [
              CustomValidators.propertyRequired,
            ])
          );
          break;
        case 'attachment':
          this.feedbackForm.addControl(
            'attachment',
            new FormControl({value: null, disabled: true})
          );
          break;
      }
    }
  }
}
