import {Pipe, PipeTransform} from '@angular/core';
import {JiraRequestType} from '@aztrix/models';

@Pipe({
  name: 'iconForJiraRequestType',
  standalone: true,
})
export class IconForJiraRequestTypePipe implements PipeTransform {
  transform(requestType: JiraRequestType): string {
    const name = requestType.name.toLowerCase();
    if (name.includes('bug')) {
      return 'bug';
    } else if (
      name.includes('feedback') ||
      name.includes('improvement') ||
      name.includes('feature')
    ) {
      return 'message-star';
    } else if (name.includes('profile')) {
      return 'alert-circle';
    } else if (name.includes('question')) {
      return 'message-question';
    } else {
      return 'message-alert';
    }
  }
}
