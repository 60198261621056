<div class="ax-modal-body-content c-modal__section">
  <mat-form-field class="c-input" appearance="outline" style="width: 100%">
    <mat-label>{{ 'modal.feedback.results.placeholder' | translate }}</mat-label>
    <input
      matInput
      type="text"
      autofocus="true"
      [formControl]="searchForm"
      (keyup)="keyup$.next(searchForm.value)"
    />
  </mat-form-field>
  <div class="search-results">
    <ax-spinner class="results-loading" *ngIf="loadingResults$ | async"></ax-spinner>
    <p *ngIf="!(searchResults$ | async)?.length && (loadingResults$ | async) === false">
      {{ 'modal.feedback.results.empty' | translate }}
    </p>
    <ng-container *ngIf="(loadingResults$ | async) === false">
      <a
        class="search-link"
        *ngFor="let searchResult of searchResults$ | async"
        target="_blank"
        [href]="searchResult.contentUrl"
      >
        <ax-icon class="icon" name="text-box"></ax-icon>
        <span>{{ searchResult.title }}</span>
      </a>
    </ng-container>
  </div>
  <a class="portal-link" [attr.href]="portalUrl$ | async" target="_blank">{{
    'modal.feedback.articles' | translate | uppercase
  }}</a>
  <div class="contact-links">
    <h2>{{ 'modal.feedback.contact' | translate }}</h2>
    <ax-spinner class="loading" *ngIf="loadingRequestTypes$ | async"></ax-spinner>
    <div
      *ngFor="let requestType of requestTypes$ | async"
      class="search-link"
      (click)="openFeedbackModal(requestType)"
    >
      <ax-icon class="icon" [name]="requestType | iconForJiraRequestType"></ax-icon>
      <span>{{ requestType.name }}</span>
    </div>
  </div>
</div>
