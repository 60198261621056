<mat-form-field *ngIf="reportedProfile" class="c-input" appearance="outline">
  <mat-label>{{ 'label.profile' | translate }}</mat-label>
  <input type="text" matInput [disabled]="true" [value]="reportedProfile | displayName" />
</mat-form-field>
<ax-spinner *ngIf="loading"></ax-spinner>
<mat-form-field *ngIf="feedbackForm.get('summary')" class="c-input" appearance="outline">
  <mat-label>{{ 'label.title' | translate }}</mat-label>
  <input type="text" matInput [formControl]="feedbackForm.get('summary')" [required]="true" />
  <mat-error>
    <ax-icon name="alert-circle"></ax-icon>
    {{ 'modal.feedback.required' | translate }}
  </mat-error>
</mat-form-field>
<mat-form-field *ngIf="feedbackForm.get('description')" class="c-input" appearance="outline">
  <mat-label>{{ 'modal.feedback.provide.description' | translate }}</mat-label>
  <textarea
    rows="4"
    [placeholder]="''"
    type="text"
    matInput
    [formControl]="feedbackForm.get('description')"
  ></textarea>
  <mat-error>
    <ax-icon name="alert-circle"></ax-icon>
    {{ 'modal.feedback.description.length' | translate }}
  </mat-error>
</mat-form-field>
<mat-form-field *ngIf="feedbackForm.get('attachment')" class="c-input" appearance="outline">
  <input
    matInput
    type="text"
    [placeholder]="'modal.bug.attachment' | translate"
    (click)="uploadBtn.click()"
    [formControl]="feedbackForm.get('attachment')"
  />
  <button
    axButton
    matSuffix
    icon-button
    (click)="
      feedbackForm.get('attachment')?.value
        ? feedbackForm.get('attachment')?.reset()
        : uploadBtn.click()
    "
    class="attachment-button"
  >
    <ax-icon
      class="icon"
      [name]="feedbackForm.get('attachment')?.value ? 'close' : 'paperclip'"
    ></ax-icon>
  </button>
  <input
    hidden
    type="file"
    accept="image/jpeg, image/png"
    (change)="selectImage($event)"
    #uploadBtn
  />
</mat-form-field>

<ng-container *ngIf="feedbackForm.get('email')">
  <ng-template #input>
    <ax-value-edit
      [form]="feedbackForm.get('email')?.get('value')"
      type="TEXT"
      [label]="'property.EMAIL.label' | translate"
      [required]="true"
    ></ax-value-edit>
  </ng-template>
  <ax-property-select
    *ngIf="emailOptions?.length; else input"
    [form]="feedbackForm.get('email')"
    [options]="emailOptions"
    [error]="'modal.feedback.required' | translate"
    [required]="true"
  ></ax-property-select>
</ng-container>

<div class="h-button-wrapper">
  <button
    axButton
    (click)="submitFeedback()"
    filled
    color="primary"
    [disabled]="feedbackForm.disabled || !feedbackForm.valid"
  >
    {{ 'label.send' | translate }}
  </button>
</div>
