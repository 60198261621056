import {HttpBackend, HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EnvironmentService} from '@aztrix/environment';
import {Observable} from 'rxjs';
import {first, map, mergeMap, switchMap, tap} from 'rxjs/operators';

interface TicketField {
  id: string;
  value: string | string[];
}

@Injectable({providedIn: 'any'})
export class FeedbackService {
  private http: HttpClient;
  constructor(backend: HttpBackend, private _environment: EnvironmentService) {
    this.http = new HttpClient(backend);
  }

  submit(
    requestTypeId: string,
    summary: string,
    email: string,
    description: string,
    attachment?: Blob
  ): Observable<any> {
    const fields: TicketField[] = [
      {id: 'summary', value: summary},
      {id: 'description', value: description},
      {id: 'email', value: email},
    ];
    if (attachment) {
      return this._uploadImage(attachment).pipe(
        tap((attachmentId) => fields.push({id: 'attachment', value: [attachmentId]})),
        mergeMap(() => this._submitTicket(requestTypeId, fields))
      );
    } else {
      return this._submitTicket(requestTypeId, fields);
    }
  }

  private _uploadImage(file: Blob): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    return this._apiUrl$('upload').pipe(
      switchMap((url) => {
        return this.http
          .post<{fileName: string; temporaryAttachmentId: string}[]>(url, formData)
          .pipe(map((result) => result[0].temporaryAttachmentId));
      })
    );
  }

  private _submitTicket(requestTypeId: string, fields: any[]): Observable<any> {
    return this._apiUrl$(`request?requestTypeId=${requestTypeId}`).pipe(
      switchMap((url) => this.http.post(url, {fields}))
    );
  }

  searchKnowledgeBase(query: string): Observable<any> {
    return this._apiUrl$(`search?query=${query}&limit=5`).pipe(
      switchMap((url) => this.http.get(url))
    );
  }

  private _apiUrl$(url: string): Observable<string> {
    return this._environment.string$('JIRA_SERVICE_API_KEY').pipe(
      first(),
      map((key) => `https://jsd-widget.atlassian.com/api/embeddable/${key}/${url}`)
    );
  }
}
