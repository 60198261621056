<mat-form-field
  appearance="outline"
  class="c-input"
  [hideRequiredMarker]="!required"
  style="width: 100%"
>
  <mat-label>{{ propertyType | propertyTypeLabel }}</mat-label>
  <mat-select
    disableOptionCentering
    [formControl]="formValue"
    [required]="required"
    [compareWith]="hasEqualValues"
  >
    <mat-option
      *ngFor="let property of options"
      [disabled]="verifiedOnly ? !(property | propertyIsVerified) : false"
      [value]="property"
    >
      {{ property | propertyDisplayValue: noValuePlaceholder }}
      <ax-icon
        *ngIf="verifiedOnly"
        class="icon checkmark"
        [ngClass]="{red: !(property | propertyIsVerified)}"
        name="custom:verified"
      ></ax-icon>
    </mat-option>
    <ng-content></ng-content>
  </mat-select>
  <mat-hint align="end">{{ hint }}</mat-hint>
  <mat-error>{{ error }}</mat-error>
</mat-form-field>
