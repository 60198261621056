import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {hasEqualValues, hasValue, isVerified} from '@aztrix/helpers';
import {Property, PropertyType} from '@aztrix/models';
import {Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

@Component({
  selector: 'ax-property-select',
  templateUrl: './property-select.component.html',
  styleUrls: ['./property-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertySelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: UntypedFormControl;
  @Input() options: Property[] = [];
  @Input() hint?: string;
  @Input() error?: string;
  @Input() required = true;
  @Input() verifiedOnly = false;
  @Input() noValuePlaceholder: string;

  formValue = new UntypedFormControl();

  hasEqualValues = hasEqualValues;

  private _subscriptions = new Subscription();

  ngOnInit() {
    this._subscriptions.add(
      this.formValue.valueChanges.subscribe((value) => {
        this.form.setValue(value);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.form) {
      throw Error('PropertySelectComponent should have a predefined FormControl as input property');
    }
    if (!hasValue(this.form.value) && this.options?.length > 0 && this.required) {
      this.form.setValue(this._firstAvailableValue || '');
    }

    if (changes.form) {
      this._subscriptions.add(
        this.form.valueChanges.pipe(startWith(this.form.value)).subscribe((property) => {
          if (!hasEqualValues(property, this.formValue.value)) {
            this.formValue.setValue(property);
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  get propertyType(): PropertyType | undefined {
    if (!this.options) {
      return undefined;
    }
    const property = this.options.length ? this.options[0] : <Property>{};
    return property.type;
  }

  get _firstAvailableValue(): Property | undefined {
    if (!this.options) {
      return undefined;
    }
    return this.verifiedOnly
      ? this.options.find((property) => isVerified(property))
      : this.options[0];
  }
}
